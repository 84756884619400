





import { Component } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';
import ExpensesTableComponent from '../components/ExpensesTableComponent.vue';

@Component({
  components: {
    ExpensesTableComponent,
  },
})
export default class ProjectBenefits extends BaseComponent {
  protected baseRoute: string = '/projects';
  private projectId: string = '';

  protected mounted() {
    if (this.$route.params.id) {
      this.projectId = this.$route.params.id;
    }
  }
}
